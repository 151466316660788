import React from 'react'

export default function SpeakLoader() {
    return (
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    )
}
