import React, { useState } from 'react';


import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import SpeakLoader from './component/SpeakLoader';

function VoiceSearch({ myReactAppProps }) {

  //console.log(props.license)

  const [transcript, setTranscript] = useState('');
  const [listening, setListening] = useState(false);

  const handleVoiceSearch = () => {

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      setListening(true);
    };

    recognition.onresult = event => {
      console.log("ev", event);
      const result = event.results[0][0].transcript;
      setTranscript(result);
    };

    recognition.onend = () => {
      setListening(false);
    };

    recognition.start();
  };


  const handleChange = (event) => {
    setTranscript(event.target.value);
  };

 
  return (
    <div className="container">
        <p>Prop 1: {myReactAppProps.prop1}</p>
      <div class="search-container">

        <input type="text" placeholder="Search..." value={transcript && transcript} onChange={handleChange}/>
        <button className="submit" onClick={handleVoiceSearch} disabled={listening}> 
        {listening ? <SpeakLoader />  : <FontAwesomeIcon icon={faMicrophone} />}
        </button>

      </div>
      <div>
        {transcript && <p className="res">You said: {transcript}</p>}
      </div>

    </div>


  );
}

export default VoiceSearch;